.header-background {
  background-image: url("./../../assets/images/header.png");
  background-size: cover;
  background-position: center;
}

.header-background:after {
  content: "";
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.header-background {
  text-align: center;
  padding: 10px;
  position: relative;
  color: #fff;
  z-index: 10;
}
